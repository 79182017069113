import { Router } from '@angular/router';
import { LowerCasePipe, isPlatformBrowser } from '@angular/common';
import JsonProcessStatus from '@utils/json/process-status.json';
import { Inject, NgModule, PLATFORM_ID } from '@angular/core';
import { StageFormValidateService } from '@apiShared/process-status/stage-form-validate/stage-form-validate.service';
import { NotificacionService } from '@utils/notificacion';
import { CookieUtil } from '@utils/cookies';
import { Globals } from '@config/global';
import { TripettoUtil } from '@utils/tripetto';
import { UtilsMethods_Services } from '@utils/utils';
import { LocalStorageUtil } from "@utils/localstorage";
import { SessionStorageUtil } from '@utils/sessionStorage';

@NgModule({
    providers: [RedirectStep]
})
export class RedirectStep {

  status_process = JsonProcessStatus;
  isviewApp?:boolean;
  cookie_process_uuid = "cookie-process-uuid";
  cookie_token_id = "cookie-token-id";
  cookie_phone = "cookie-phone";
  personal_data_updated = "personal_data_updated";
  financial_data_updated = "financial_data_updated";
  response_data_personal = "response_data_personal";
  updating_personal_data = "updating_personal_data";
  response_data_financial = "response_data_financial";
  updating_financial_data = "updating_financial_data";

  constructor(
    public lowerCasePipe: LowerCasePipe,
    public cookies_util: CookieUtil,
    public globals_var: Globals,
    public tripetto_util: TripettoUtil,
    public utils: UtilsMethods_Services,
    private router: Router,
    private notificacion_components: NotificacionService,
    private stage_form_validate_services: StageFormValidateService,
    public localstorage_util: LocalStorageUtil,
    public sessionStorage_util: SessionStorageUtil,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if(this.cookies_util.check('view')){
      this.isviewApp = true
    } else {
      this.isViewApp();
    }
  }

  _RedirectStep(obj: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      let bodyStageValidate = {
        data: {
          process_uuid: this.cookies_util.get('id_process')
        },
        idToken: this.cookies_util.get('id_token_user'),
      };

      if (obj.required_identity_validation) {
        this.RouterHTTP('/validar-identidad', '');
        resolve();
      } else {
        if (obj.process_status == "LEAD-SIN-FORMULARIO") {
          this.stage_form_validate_services.STAGE_FROM_VALIDATE(bodyStageValidate).then((data) => {
            let body = data.body;
            if(data.status === 200){
              /*
                Enviaremos una notificacion a todos los componetes, principalmente para indicarle a
                datos-personales si mostrar el modal de Actualizar datos o no
              */
              this.notificacion_components.sendNotificacion({
                api: "send_information_stage_form_validate",
                data: body
              });
              if (obj.id_bank === this.globals_var.ID_FLAMINGO) {
                // Redireccionar a /datos-personales con el query parameter 'Flamingo'
                this.RouterHTTP(body.path, this.globals_var.FLAMINGO);
              } else if (
                obj.id_bank === this.globals_var.ID_BAN100 ||
                obj.id_bank === this.globals_var.ID_BANCOW ||
                obj.id_bank === this.globals_var.ID_COLTEFINANCIERA ||
                obj.id_bank === this.globals_var.ID_DANNREGIONAL ||
                obj.id_bank === this.globals_var.ID_MUNDOMUJER ||
                obj.id_bank === this.globals_var.ID_MIBANCO ||
                obj.id_bank === this.globals_var.ID_BANCAMIA || 
                obj.id_bank === this.globals_var.ID_CREDIFAMILIA
              ) {
                // Redireccionar a /datos-personales sin query parameter
                if (body.path.startsWith("/preguntas")) {
                  if (!this.sessionStorage_util.check(this.personal_data_updated)) {
                    const personalData = this.createPersonalData(body);
                    this.createFinancialData(body);
                    this.redirectTo("/datos-personales", this.response_data_personal, personalData, this.updating_personal_data);
                    return;
                  }
                
                  if (!this.sessionStorage_util.check(this.financial_data_updated)) {
                    const financialData = this.createFinancialData(body);
                    console.log(this.response_data_financial);
                    console.log(financialData);
                    console.log(this.updating_financial_data);
                    this.redirectTo("/datos-financieros", this.response_data_financial, financialData, this.updating_financial_data);
                    return;
                  }
                }
                  this.RouterHTTP(body.path, '');

              } else {
                // Redireccionar a /vinculacion-{name_bank} sin query parameter
                this.RouterHTTP("/vinculacion-" + this.lowerCasePipe.transform(obj.name_bank).replace(' ', '-'), '');
              }
            }
            resolve();
          }).catch(reject);
        } else {
          // Obtener la URL correspondiente al estado del proceso
          const url = this.status_process['raw'][obj.process_status]['url'];
          // Redireccionar a la URL con el query parameter 'Flamingo' si es necesario
          this.RouterHTTP(url, obj.id_bank === this.globals_var.ID_FLAMINGO ? this.globals_var.FLAMINGO : '');
          resolve();
        }

      }

    });
  }

  RouterHTTP(routerPath: string, campaign: string) {
    if(isPlatformBrowser(this.platformId)){
      const queryParams: any = {};
      // Obtener el valor de force de los parámetros de consulta
      const forceForm = this.utils.getQueryParam('force') === 'OK';
      // Obtener la ubicación actual de la página
      const locationPath = window.location.pathname;
      // Definir rutas válidas
      const pathValid = [
        '/preguntas/coltefinanciera/flamingo?campaign=Flamingo',
        '/preguntas-ban100',
        '/preguntas-bancamia',
        '/preguntas-bancow',
        '/preguntas-bancomundomujer',
        '/preguntas-mibanco',
        '/preguntas-credifamilia',
        '/preguntas-coltefinanciera',
        '/preguntas-financieradannregional',
        '/datos-personales',
        '/datos-financieros'
      ];
      // Si forceForm es verdadero, agregar el parámetro force
      if (forceForm) {
        queryParams.force = 'OK';
        // Si la ubicación actual es una ruta válida, usarla como routerPath
        if (pathValid.includes(locationPath)) {
          routerPath = locationPath;
        }
      }
      // Si campaign es igual a FLAMINGO, agregar el parámetro campaign
      if (campaign === this.globals_var.FLAMINGO) {
        queryParams.campaign = this.globals_var.FLAMINGO;
      }
      // Si isviewApp es verdadero, agregar el parámetro view
      if (this.isviewApp) {
        queryParams.view = 'app';
      }
      // Navegar a la ruta especificada con los query parameters
      this.router.navigate([routerPath], { queryParams });
      // Notificar que la redirección fue exitosa
      setTimeout(() => {
        this.notificacion_components.sendNotificacion({
          api: "send_information_finish_redirect"
        });
      }, 500);
    }
  }

  isViewApp(){
    if(isPlatformBrowser(this.platformId)){
      //Validamos si la vista la abren desde el aplicativo
      const url = new URL(window.location.href);
      const viewParam = url.searchParams.get('view');
      // Verificar si viewParam es igual a 'app' (asegurando que viewParam no sea nulo)
      if(this.cookies_util.check('view')){
        this.isviewApp = true
      } else if(viewParam !== null && viewParam === 'app'){
        this.isviewApp = true;
        this.cookies_util.save('view', 'app', 1)
      }
    }
  }

  createFinancialData(body: any) {
     
    const data=JSON.stringify({
      [this.cookie_process_uuid]: this.cookies_util.get('id_process'),
      [this.cookie_token_id]: this.cookies_util.get('id_token_user'),
      [this.cookie_phone]: this.cookies_util.get('phone-full'),
      "incomes.monthly_incomes": String(body.financial_data.monthly_incomes),
      "total_assets": String(body.financial_data.total_assets),
      "total_liabilities": String(body.financial_data.total_liabilities),
      "is_income_statement": body.financial_data.is_income_statement === "true" ? "True" : "False",
    });


    this.localstorage_util.save(this.response_data_financial, data);
    return data
  }

  createPersonalData(body: any) {
    const data= JSON.stringify({
      [this.cookie_process_uuid]: this.cookies_util.get('id_process'),
      [this.cookie_token_id]: this.cookies_util.get('id_token_user'),
      [this.cookie_phone]: this.cookies_util.get('phone-full'),
      ...body.personal_data
    });

    
    this.localstorage_util.save(this.response_data_personal, data);
    return data;
  }

  redirectTo(path: string, responseDataKey: string, data: any, updatingDataKey: string) {
    this.RouterHTTP(path, '');
    this.sessionStorage_util.save(updatingDataKey, "true");
  }
}
