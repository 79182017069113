import { Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Router } from '@angular/router';
import { CookieUtil } from '@utils/cookies';

import { UtilsMethods_Services } from '@utils/utils';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '@enviroment/environment';
import { getDataCampaign } from '@utils/get-campaign';
import { SharedServiceCompleted } from '@apiShared/shared-service/shared-services.service.compontent';
import { Subscription, filter } from 'rxjs';
import { ValidateTokenService } from '@apiShared/user/token/validate-token.service';
import { SignOut_Util } from '@utils/sign-out';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertConfirmComponent } from './resources-alerts/alert-confirm/alert-confirm.component';
import { CloseModal } from '@class/close-modal/close-modal';
import { Globals } from '@config/global';
import { NotificacionService } from '@utils/notificacion';

declare let gtag: Function;

declare global {
  var HubSpotConversations: {
    widget: {
      remove: () => void;
      load: () => void;
      open: () => void;
    };
  };
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {

  private tokenExpirationSubscription!: Subscription;
  env = environment;
  title = 'MejorCDT';
  retryAttempts = 5; // Número máximo de intentos para checkQueryParam
  favicon!: any;
  mouseInactive: number = 0;
  flagMouseActive: boolean = false;
  timeMouseActive: number = 35000;

  pageNotAllowedBonusGift: any = [
    '/preguntas/coltefinanciera/flamingo',
    '/preguntas-ban100',
    '/preguntas-bancamia',
    '/preguntas-bancow',
    '/preguntas-bancomundomujer',
    '/preguntas-mibanco',
    '/preguntas-coltefinanciera',
    '/preguntas-credifamilia',
    '/preguntas-financieradannregional',
    '/datos-personales',
    '/datos-financieros',
    '/sube-tus-documentos',
    '/asegura-tu-tasa',
    '/transfiere-el-dinero',
    '/cdt-completado',
    '/ops/dashboard',
    '/portal/login',
    '/portal/dashboard',
    '/login',
    '/verificando-firma',
    '/mis-procesos',
    '/continuar-proceso',
    '/no-podemos-continuar-con-tu-proceso',
    '/firma-los-documentos-en-fisico',
    '/trabajo',
    '/validar-identidad',
    "/hoy-los-bancos-ofrecen",
    "/cdt-simulador?promo=gift-voucher",
    "/golf18",
    "/a-unos-minutos-de-abrir-tu-cdt",
    "/cdt-simulador",
    "/orden-inversion",
    "/cancelacion",
    "/4B6XN8K2H7VY3W9M5Q1Z9A3JN7M5D1H8S4L0F2K",
    "/dias-azules",
    "/sueldo-inteligente",
    "/referidos",
    "/referidos/terminos",
    "/cdt-nicolasabril",
    "/cdt-financiatuvida",
    "/cdt-claudiauribe"
  ];

  constructor(
    public cookies : CookieUtil,
    public utils : UtilsMethods_Services,
    public signOut: SignOut_Util,
    public closeModal: CloseModal,
    public notification: NotificacionService,
    public _getDataCampaign: getDataCampaign,
    private modal: NgbModal,
    private validateToken: ValidateTokenService,
    private router: Router,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private _CompletedSharedServices:SharedServiceCompleted,
    private globals_var: Globals,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)){
      this.favicon = document.querySelector("#favicon");
      this.loadHammerJs();
      //this.validateCampaignVIP8();
      this.addClassPathInView();
      this.changeFaviconFlamingo(); //Se cambia de favicon si es flamingo
      this.changeHubspotChat(); // Suscripción a un observable para detectar cuando se completa una función compartida ()
      this.campaignVIP(); // Activación de CSS para Campaña VIP
    }

    this._CompletedSharedServices.functionCompleted.subscribe((data: any) => {
      // Verificar si los datos no son nulos
      if (data != null) {
        // Verificar si 'function_completed' existe en los datos
        if ('function_completed' in data) {
          this.route.queryParams.subscribe(params => {
            if('campaign' in params){
              let campaign = String(params['campaign']).toLowerCase();
              this.activateCampaign(campaign);
              this.checkQueryParam();
            }
          });
        }
      }
    });

  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)){
      const queryParams = new URLSearchParams(window.location.search);
      const campaign = queryParams.get('campaign')?.toLowerCase();

      const check_id_token_user = this.cookies.check("id_token_user");
      if(check_id_token_user){
        this.startTokenExpirationCheck()
      }

      /* Traemos el queryparam de referidos */
      this.RefInfluencer('ref');
      this.checkQueryParam();
      this.backToStep();

      this.activateCampaign(campaign);
    }
  }

  loadHammerJs() {
    import('hammerjs');
  }

  activateCampaign(campaign: any){
    if (isPlatformBrowser(this.platformId)){
      this._getDataCampaign.activateCampaignData(campaign).subscribe(data => {
        this._getDataCampaign.campaignData = data;
      });;
    }
  }

  addClassPathInView() {
    this.router.events.subscribe((event:any) => {
      if (event instanceof NavigationEnd) {
        const element:any = document.getElementById('aplication-tubo-mcdt');
        if (element) {
          // Remover todas las clases
          while (element.classList.length > 0) {
            element.classList.remove(element.classList.item(0));
          }

          // Obtener el path y reemplazar '/' con '_'
          const path = window.location.pathname.replace('/', '_');

          if (path) {
            // Añadir la clase correspondiente al path
            this.renderer.addClass(element, path);
          }
        }
      }
    });
  }


  campaignVIP(){
    this.notification.receiveNotificacion.subscribe((data: any) => {
      if(data.api === "api_notification_activate_VIP"){
        const element = document.getElementById('project-mcdt');
        if (element) {
          this.renderer.addClass(element, 'vip-activate');
        }
      }
    })
  }

  changeFaviconFlamingo(){
    this.route.queryParams.subscribe((params: any) => {
      const campaign = params['campaign'] || '';
      if( campaign.toLowerCase() === this.globals_var.FLAMINGO.toLowerCase() ) {
        this.favicon.href = "assets/favicons/flamingo.png";
      }
    });
  }

  changeHubspotChat(){
    this._CompletedSharedServices.functionCompleted.subscribe((data: any) => {
      // Verificar si los datos no son nulos
      if (data != null) {
        // Verificar si 'function_completed' existe en los datos
        if ('function_completed' in data) {
          // Llamar a la función para cargar el chat de hubspot (Para determinar que chat cargar, si el default o flamingo)
          this.checkQueryParam();
        }
      }
    });
  }

  @HostListener('document:mouseleave', ['$event'])
  onMouseLeave(event: MouseEvent) {
    if (isPlatformBrowser(this.platformId)){
      const locationPath = window.location.pathname;
      const is_phone_full = this.cookies.check('phone-full');
      const is_phone_number = this.cookies.check('phone-number');
      const is_token_user = this.cookies.check('id_token_user');
      if(!this.pageNotAllowedBonusGift.includes(`${locationPath}`) && !is_phone_full && !is_phone_number  && !is_token_user){
        if(!this.flagMouseActive){
          setTimeout(() => {
            this.flagMouseActive = true;
          }, this.timeMouseActive);
        } else {
          this.mouseInactive++;
          if(!this.cookies.check("open_modal_gift")){
            this.cookies.save("open_modal_gift", "false", 360);
            this.openAlertConfirmation({
              title: `
              <div class="text-center">
                <img src="${this.env.S3_STATIC_FILES}/images/web/ilustrations/banner-gift.png"> </img>
                ¡Tenemos un regalo especial para ti! <br/>
              </div>

              `,
              message: `
              <span class='description-gift'>
                Abre tu CDT ${this.generateDynamicMessage()} <br/> <br/>
                Este bono regalo se sumará exclusivamente <br/> al valor de tu inversión
              </span>
              `,
              no_padding_header: true,
              textButtons: {
                confirm: {
                  text: "Quiero reclamar mi bono regalo",
                  id: 'select_claim_bonus50k'
                },
                cancel: {
                  text: "No me interesa",
                  id: 'select_reject_bonus50k '
                }
              }
            }, () => { this.acceptedAsGift() });
          }
        }
      }
    }
  }

  /*
  * Genera un mensaje dinámico dependiendo del día de la semana.
  * Si hoy es jueves antes de las 3:00 PM, devuelve un mensaje específico.
  * Si hoy es viernes, devuelve un mensaje para el próximo viernes.
  * En cualquier otro día, devuelve un mensaje para el próximo viernes después del día actual.
  * @returns El mensaje dinámico generado.
 */
  generateDynamicMessage(): string {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
    const textContinue = 'y recibe gratis un bono regalo de <b>$50.000</b>'
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
    // Verificar si hoy es jueves y si es antes de las 3:00 PM
    if (dayOfWeek === 5) {
      const now = new Date();
      const cutoffTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 15, 0, 0); // 3:00 PM

      if (now < cutoffTime) {
        return "<b>hoy antes de las 3:00pm</b> para que recibas gratis un bono regalo de <b>$50.000</b> ";
      }
    }
    // Si hoy es viernes, mostrar el mensaje para el próximo viernes
    else if (dayOfWeek === 5) {
      const nextFriday = new Date(today);
      nextFriday.setDate(today.getDate() + 7); // Obtener el próximo viernes

      const day = nextFriday.getDate();
      const monthIndex = nextFriday.getMonth();
      const month = months[monthIndex].charAt(0).toUpperCase() + months[monthIndex].slice(1);
      const year = nextFriday.getFullYear();

      return `<b>antes del próximo Viernes ${day} de ${month} de ${year}</b> a las 3:00pm,  ${textContinue}`;
    }

    // Si no es jueves ni viernes, mostrar el mensaje para el próximo viernes después del día actual
    const nextFriday = new Date(today);
    nextFriday.setDate(today.getDate() + ((5 - dayOfWeek + 7) % 7)); // Obtener el próximo viernes

    const day = nextFriday.getDate();
    const monthIndex = nextFriday.getMonth();
    const month = months[monthIndex].charAt(0).toUpperCase() + months[monthIndex].slice(1);
    const year = nextFriday.getFullYear();

    return `<b>antes del próximo Viernes ${day} de ${month} de ${year}</b> a las 3:00pm, ${textContinue}`;
  }

  acceptedAsGift(){
    this.cookies.save('activate_promo', 'true', 1)
    this.router.navigate(['/cdt-simulador'], { queryParams: { promo: 'gift-voucher'} } )
  }

  openAlertConfirmation(modalData: {title: string, message: string, textButtons: {}, no_padding_header: boolean}, execute: () => void) {
    if(this.mouseInactive <= 1){
      const modalRef = this.modal.open(AlertConfirmComponent,
        {
          centered: true,
          backdrop: 'static',
          size: 'modal-alert-bono-regalo-50k-zoom'
        }
      );
      modalRef.componentInstance.modalData = modalData;
      modalRef.componentInstance.getResult().subscribe((result: boolean) => {
        if (result) {
          execute();
        }
      });
    }
  }

  startTokenExpirationCheck(): void {
    this.tokenExpirationSubscription = this.validateToken.getTokenExpirationStatus()
      .subscribe((isExpired: boolean) => {
        if (isExpired) {
          if(this.cookies.get('id_token_user')){
            this.signOut.SignOut('');
            this.stopTokenExpirationCheck();
            this.closeModal._CloseModal('btn-close-modal-loading');
            this.router.navigate(['/login']);
          }
        }
      });
  }

  stopTokenExpirationCheck(): void {
    if (this.tokenExpirationSubscription) {
      this.tokenExpirationSubscription.unsubscribe();
    }
  }

  //Desde la función redirect, detectaremos si viene el queryparam de campaign, y si si, añadimos en el head el script de Flamingo,
  checkQueryParam() {
    this.route.queryParams.subscribe(params => {
      if (params['campaign']) {
        // Si el query parameter 'campaign' existe, cargar el script directamente
        this.addScriptToHead('//js.hs-scripts.com/23231946.js');
      } else if (this.retryAttempts > 0) {
        // Si no hay 'campaign' y aún hay intentos restantes
        this.retryAttempts--; // Reducir el contador de intentos
        setTimeout(() => {
          this.checkQueryParam(); // Reintentar después de 1 segundo
        }, 1000);
      } else {
        // Si no hay 'campaign' y se agotaron los intentos, cargar el script
        this.addScriptToHead('//js.hs-scripts.com/23231946.js');
      }
    });
  }

  /* Si existe el paremetro tube, significa que el usuario accedio por el link y lo redireccionaremos al paso en que está  */
  backToStep(){
    const isIdProcess = !!this.utils.getQueryParam('process_id');
    const isIdTicket = !!this.utils.getQueryParam('ticket');
    const isPathHome = window.location.pathname === '/';
    const query_url =  {
      process_id: this.utils.getQueryParam('process_id'),
      ticket: this.utils.getQueryParam('ticket'),
      campaign: this.utils.getQueryParam('campaign')
    };
    if ((isIdProcess || isIdTicket) && isPathHome) {
      this.router.navigate(['/continuar-proceso'], { queryParams: query_url })
    }
  }

  /* Identificamos de que referido viene */
  RefInfluencer(query: any){
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    var resp = urlParams.get(query);

    if(resp != null){
      this.cookies.save('ref', resp, 1);
    }
  }

  // Función para agregar el script de HS al elemento <head> del documento
  addScriptToHead(scriptUrl: string) {
    if (isPlatformBrowser(this.platformId)){
      // Crear un elemento <script> usando el renderer
      const scriptHS = this.renderer.createElement('script');
      scriptHS.type = 'text/javascript';
      scriptHS.src = scriptUrl;

      // Agregar el elemento <script> al elemento <head> del documento
      this.renderer.appendChild(document.head, scriptHS);

      // Configurar un evento onload para el script
      scriptHS.onload = () => {
        // Esperar a que HubSpotConversations esté disponible antes de acceder a la propiedad widget
        this.waitForHubSpotConversations().then(() => {
          // Verificar si HubSpotConversations y su propiedad widget existen
          if (window.HubSpotConversations && window.HubSpotConversations.widget) {
            // Acceder a la propiedad widget y ejecutar métodos
            window.HubSpotConversations.widget.remove(); // Remover el widget actual
            window.HubSpotConversations.widget.load();   // Cargar el widget
          }
        });
      };
    }
  }

  // Función para esperar a que HubSpotConversations esté disponible
  waitForHubSpotConversations(): Promise<void> {
    return new Promise((resolve) => {
      const checkHubSpotConversations = () => {
        // Verificar si HubSpotConversations está disponible en la ventana global
        if (window.HubSpotConversations) {
          resolve(); // Resuelve la promesa cuando HubSpotConversations está disponible
        } else {
          setTimeout(checkHubSpotConversations, 100); // Revisar cada 100 ms hasta que esté disponible
        }
      };
      // Iniciar el proceso de espera
      checkHubSpotConversations();
    });
  }

}
