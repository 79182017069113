import { Injectable } from "@angular/core";
import { GetUserMeService } from 'src/app/api-shared/user/get/get-user-me.service';
import { NotificacionService } from '@utils/notificacion';
import { CookieUtil } from "@utils/cookies";

@Injectable({
  providedIn: 'root'
})

export class GetUserMe {
  constructor(
    private notificacion_components: NotificacionService,
    private get_user_me: GetUserMeService,
    public cookies_util: CookieUtil
  ){ }

  /* Consultamos la informacion del usuario */
  UserMe_Helper(idToken: any): Promise<any>{
    return new Promise((resolve) => {
      this.get_user_me.GET_USER_ME(idToken).then(data => {
        /* Conectamos el servicio con el componente de login con el fin de compartirle la información del usario, si esté existe o no */
        let share_information_user_me = {
          "api": "share_information_at_login_component",
          "data": data
        };
        /* Hacemos la conexión y le compartimos la información del usuario */
        this.notificacion_components.sendNotificacion(share_information_user_me);
        /* Si el usuario existe, subiremos el nombre y su celular a una cookie: Estás cookies son importantes */
        if(data.code === "OK"){
          this.cookies_util.save('fullname', data.full_name, 1);
          this.cookies_util.save('phone-full', data.phone, 1);
          resolve(200);
        } else {
          resolve(404);
        }
      });
    });
  }

}
