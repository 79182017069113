import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@enviroment/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class getDataCampaign {

  public campaignData         : any;
  private campaignDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  campaignData$: Observable<any> = this.campaignDataSubject.asObservable();
  
  favicon!                    : HTMLLinkElement | null;
  

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  activateCampaignData(campaign:any): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      let url = campaign === 'flamingo' ? '../../assets/i18n/flamingo.json' : '../../assets/i18n/mcdt.json';
      return this.http.get<any>(url).pipe(
        tap(data => {
          this.campaignDataSubject.next(data); // Emitir los nuevos datos
          this.campaignData = data; // Almacena los datos en la caché
          if (campaign === 'flamingo') {
            this.favicon = document.querySelector("#favicon");
            if (this.favicon) {
              this.favicon.href = `${environment.S3_STATIC_FILES}/images/web/favicons/flamingo.png`;
            }
          }
        })
      );
    } else {
       return of({})
    }
  }
  

  getCampaignData(): any {
    return this.campaignData;
  }

  getCampaignDataUpdates(): Observable<any> {
    return this.campaignDataSubject.asObservable();
  }
}
