import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, PLATFORM_ID } from "@angular/core"
import { environment } from '../../../environments/environment';
import { isPlatformBrowser } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private endpoint:string = 'auth/';
  private domain:any;
  private version:any;
  private api_key:any;
  private HTTPS_OPTIONS: any;

  constructor(
    private http:HttpClient,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { 
    this.version = `${environment.version}` 
    this.domain = `${environment.API_BASE_URL}`;
    this.api_key = environment.API_KEY;

    this.HTTPS_OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': `auth-${this.api_key}`
      }) 
    }
  }

  SIGN_UP(data: any): Promise<any>{
    if(isPlatformBrowser(this.platformId)){
      return this.http.post(this.domain + this.endpoint + 'sign-up', data, this.HTTPS_OPTIONS).toPromise()
      .then(this.extractData)
      .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }

  SIGN_IN(data: any): Promise<any>{
    if(isPlatformBrowser(this.platformId)){
      return this.http.post(this.domain + this.endpoint + 'sign-in', data, this.HTTPS_OPTIONS).toPromise()
      .then(this.extractData)
      .catch(this.extractData);
    }
    return Promise.reject('Server Side Rendering');
  }


  private extractData(res: any) {
    let body = res;
    return body;
  }
}
