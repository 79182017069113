import { Injectable } from '@angular/core';
import { GetProcessStatusService } from 'src/app/api-shared/process-status/get/get-process-status.service';
import JsonProcessStatus from '../../utils/json/process-status.json';
import JsonBanks from '../../utils/json/banks.json'
import { RedirectStep } from '../redirect-step/redirect-step';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieUtil } from '@utils/cookies';
import { Router } from "@angular/router";
import { AllProcessViewComponent } from 'src/app/modals-shared/all-process-view/all-process-view.component';
import { ModalSharedLoginComponent } from 'src/app/modals-shared/login/login.component';

@Injectable({
  providedIn: 'root'
})

export class ProcessBlockingControlAndRedirectViaStatus {
  /* Pasamos la información del json de process-status a variable */
  status_process = JsonProcessStatus;
  /* Transformación para obtener el nombre del banco por medio del id */
  id_banks = JsonBanks;
  constructor(
    public cookies_util: CookieUtil,
    private get_process_information: GetProcessStatusService,
    private _redirectStep: RedirectStep,
    private modalService: NgbModal,
    private router: Router
  ) {}

  public ProcessBlockingAndRedirect(objParamaters: any){
    const id_process = this.cookies_util.get('id_process'),
          id_token = this.cookies_util.get('id_token_user'),
          bank_id = objParamaters.bank_id;

    /* Necesitamos consultar primero el proceso para bloquear o detectar si ha habido un cambio del estado del proceso */
    if(!Boolean(id_token)){
      /* Si no existe la cookie (id_token_user), no hay login, por tanto, vamos a necesitar activar el componente del Loogin. */
      /* En este caso, mandaremos un step igual a 0, indicando que el login se abrirá dentro de un paso pero sin activar la sección de crear proceso */
      this.openLoginModal(
        {
          "data": {
            "step": 3,
            "id_process": Boolean(id_process),
            "disable_modal_close_button": true,
            "open_from_step": true
          },
          "modal": ""
        }
      );

    } else {
      /* Si existe la cookie de id_token_user pero no la id_process, quiere decir que el usuario se logueo pero por alguna razón cerro la vista, por tanto no escogió un proceso, quiere decir que ocurrirá un error al tratar de consumir el servicio de id_process en el componente de step-indicator, ya que no existe la cookie de id_process */
      if(!Boolean(id_process)){
        this.openAllProcesessModal({
          "api": "active_all_process_component",
          "data": {
            "open_from_step": true,
          },
          "id_token": id_token,
          "step": 0,
          "modal": ""
        })
      } else {
        this.get_process_information.GET_PROCESS_STATUS(id_process, id_token).then((data: any) => {
          let body = data.body;
          /*En caso de que por motivos del banco el usuario no pueda continuar con el proceso de apertura de CDT se envía a la pantalla informativa */
          if(body.extra.block_process) {
            this.router.navigate(['/no-podemos-continuar-con-tu-proceso']);
            return;
          }

          const name_bank = this.id_banks['raw'][body.bank_id]['bank'];
          /* En caso de que entren a otro paso en el cuál el back no haya habilitado, se redireccionara al paso actual */
          if(objParamaters.status_search.filter((name:any) => name.includes(body.process_status)).length == 0){
            this._redirectStep._RedirectStep({
              name_bank                     : name_bank,
              process_status                : body.process_status,
              id_bank                       : body.bank_id,
              required_identity_validation  : (body.extra.required_identity_validation && body.identity_validation === null)
            });
          } else if( Boolean(bank_id) && (bank_id != this.id_banks['raw'][body.bank_id]) ){
            this._redirectStep._RedirectStep({
              name_bank                     : name_bank,
              process_status                : "LEAD-SIN-FORMULARIO",
              id_bank                       : body.bank_id,
              required_identity_validation  : (body.extra.required_identity_validation && body.identity_validation === null)
            });
          } else {
            this._redirectStep._RedirectStep({
              name_bank                     : name_bank,
              process_status                : body.process_status,
              id_bank                       : body.bank_id,
              required_identity_validation  : (body.extra.required_identity_validation && body.identity_validation === null)
            });
          }
        });
      }

    }
  }

  openLoginModal(data:any){
    const modalRef = this.modalService.open(
      ModalSharedLoginComponent,
      {
        centered: true,
        size: 'sm',
        backdrop:'static'
      }
    );
    modalRef.componentInstance.LoginDataModal = data;
  }

  openAllProcesessModal(data:any){
    const modalRef = this.modalService.open(
      AllProcessViewComponent,
      {
        centered: true,
        size: 'lg width-all-process',
        backdrop:'static'
      }
    );
    modalRef.componentInstance.AllProcessDataModal = data;
  }

}
