import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getDataCampaign } from '@utils/get-campaign';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-alert-confirm',
  templateUrl: './alert-confirm.component.html',
  styleUrls: ['./alert-confirm.component.css']
})
export class AlertConfirmComponent {
  @Input() modalData!: {
    title: string,
    message: string,
    no_padding_header: boolean,
    textButtons: {
      confirm: {
        text: string,
        id: string
      },
      cancel: {
        text: string,
        id: string
      }
    }
  };
  private resultSubject: Subject<boolean> = new Subject<boolean>();
  getCampaign:any = {};

  constructor(
    public activeModal: NgbActiveModal,
    public _getDataCampaign: getDataCampaign,
  ) { 
    this._getDataCampaign.campaignData$.subscribe(data => {
      this.getCampaign = data; // Actualiza los datos de la campaña en el subcomponente
    });
  }

  confirm() {
    this.resultSubject.next(true);
    this.activeModal.close();
  }

  cancel() {
    this.resultSubject.next(false);
    this.activeModal.dismiss();
  }

  getResult(): Subject<boolean> {
    return this.resultSubject;
  }

}
