import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '@config/global';
import { environment } from '@enviroment/environment';
import { getDataCampaign } from '@utils/get-campaign';

@Component({
  selector: 'app-bank-not-allied',
  templateUrl: './bank-not-allied.component.html',
  styleUrls: ['./bank-not-allied.component.css']
})
export class BankNotAlliedComponent implements OnInit {
  env = environment;
  bankname_options:any;
  /* Imagen para cargar por default para aquellos bancos no aliados, de esta manera se cargará más rapido la imagen */
  imageBankNotAllied:any = `${this.env.S3_STATIC_FILES}/images/web/warnig-bank-not-allied-2.webp`;
  /*  */
  selectedBank: any;
  getCampaign:any = {};
  constructor(
    public activeModal: NgbActiveModal,
    public globals_var: Globals,
    public _getDataCampaign: getDataCampaign,
  ) {
    this._getDataCampaign.campaignData$.subscribe(data => {
      this.getCampaign = data; // Actualiza los datos de la campaña en el subcomponente
    });
  }

  ngOnInit(): void {
    this.operatorView(this.bankname_options);
  }

  operatorView(bankname:any){
    this.selectedBank = bankname;
  }

  
}
