import { Injectable } from '@angular/core';
import { CreateProcessUserService } from 'src/app/api-shared/process-status/create/create-process-user.service';
import { RedirectStep } from '../../redirect-step/redirect-step';
import { CloseModal } from 'src/app/class/close-modal/close-modal';
import { SharedServiceCompleted } from '@apiShared/shared-service/shared-services.service.compontent';
import { CookieUtil } from '@utils/cookies';

@Injectable({
  providedIn: 'root'
})

export class CreateProcessUser {
  constructor(
    private post_create_user: CreateProcessUserService,
    public cookies_util : CookieUtil,
    private _redirectStep: RedirectStep,
    private _closeModal: CloseModal,
    private _CompletedSharedServices:SharedServiceCompleted
  ){  }
  public CreateProcess_Helper(objParamaters:any): Promise<any>{ 
    return new Promise((resolve) => {
      this.post_create_user.CREATE_PROCESS_USER(objParamaters.data, objParamaters.idToken).then((data: any) => {
        let status = data.status;
        let response = data.body;
        if(status === 201 || status === 200){
          
          /* Subimos el id del proceso creado a una cookie */
          this.cookies_util.save('id_process', response.process_uuid, 1);
          /* Redireccionamos al formato de vinculación dependiendo del banco del nombre */
          this._redirectStep._RedirectStep({
            "name_bank": objParamaters.bank_name,
            "process_status": "LEAD-SIN-FORMULARIO",
            "id_bank": objParamaters.data.bank_id
          }).then(() => {
            
          }).catch(error => {
            console.error("An error occurred during redirection:", error);
          });
          /* 
            Comunicaremos a demas componentes que se ha creado el proceso exitosamente, por tanto, activaremos funciones.
            por ejemplo: en el header, volveremos activar la información del usuario para ver el nombre en el menu
          */
          this._CompletedSharedServices.setFunctionCompleted({
            "function_completed": true,
          });

          
          resolve(200);

          setTimeout(() => {
            this._closeModal._CloseModal(objParamaters.modal);
          }, 1000);

        } else {
          resolve(400);
        }
      }); 
    });
  }
}