import { Injectable, Inject, HostListener, PLATFORM_ID, NgZone } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { LocalStorageUtil } from './localstorage';
import { ConvertQuestionsImportUtil } from './convert-questions-import';
import { CookieUtil } from './cookies';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '@config/global';
import { UtilsMethods_Services } from './utils';
import { NotificacionService } from './notificacion';

@Injectable({
  providedIn: 'root',
})

export class TripettoUtil {

    constructor(
      public utils: UtilsMethods_Services,
      public cookies_util                 :   CookieUtil,
      public localstorage_util            :   LocalStorageUtil,
      public convertQuestionImport_util   :   ConvertQuestionsImportUtil,
      public route                        :   ActivatedRoute,
      public globals_var                  :   Globals,
      public router                       :   Router,
      public notificacion_components      :   NotificacionService,
      public ngZone                       :   NgZone,
      @Inject(PLATFORM_ID) public platformId: Object,
    ) {}

    checkQuestion(){
      let importquerys:any;
      if (isPlatformBrowser(this.platformId)){
        if (this.localstorage_util.check('questions')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('questions')
          );
        } else if (this.localstorage_util.check('response_data_personal')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_personal')
          );
        } else if (this.localstorage_util.check('response_data_financial')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_financial')
          );
          
        } else if (this.localstorage_util.check('response_data_specific_banco_w')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_specific_banco_w')
          );
        } else if (this.localstorage_util.check('response_data_specific_bancamia')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_specific_bancamia')
          );
        }else if (this.localstorage_util.check('response_data_specific_ban100')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_specific_ban100')
          );
        } else if (this.localstorage_util.check('response_data_specific_coltefinanciera')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_specific_coltefinanciera')
          );
        } else if (this.localstorage_util.check('response_data_specific_dan_regional')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_specific_dan_regional')
          );
        } else if (this.localstorage_util.check('response_data_specific_flamingo')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_specific_flamingo')
          );
        } else if (this.localstorage_util.check('response_data_specific_mibanco')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_specific_meibanco')
          );
        } else if (this.localstorage_util.check('response_data_specific_mundo_mujer')) {
          importquerys = this.convertQuestionImport_util.convertQuestionImportsForm(
            this.localstorage_util.get('response_data_specific_mundo_mujer')
          );
        }
        return importquerys;
      }   
    }

    redirectLoginExpireCookie(body_map:any){
      if (isPlatformBrowser(this.platformId)){
        this.localstorage_util.save("questions", JSON.stringify(body_map));
        let queryParams:any = {};
        queryParams.return = "form";
        setTimeout(() => {
          this.ngZone.run(() => {
            this.router.navigate(['/login'], { queryParams });
          });
        }, 1000);
      }
      
  }

    navigateURL(url: string) {
      if (isPlatformBrowser(this.platformId)){
        // Inicializamos la URL de navegación con la URL proporcionada
        let navigateURL = url;

        if (navigateURL === '/sube-tus-documentos') {
          this.localstorage_util.delete('questions');
          this.ngZone.run(() => {
            this.router.navigate(['/sube-tus-documentos']);
          });
        } else {
          // Comprobamos si los parámetros de consulta son verdaderos
          const queryCampaignFlamingo = this.getQueryParamsURL('campaign') === this.globals_var.FLAMINGO;
          const queryViewApp = this.getQueryParamsURL('view') === 'app';
          const queryForce = this.getQueryParamsURL('force') === 'OK';
          let queryParams: any = {}; // Inicializamos un array para almacenar los parámetros de consulta
          // Comprobamos si alguno de los parámetros de consulta es verdadero
          if (queryCampaignFlamingo || queryViewApp || queryForce) {
            // Si queryViewApp es verdadero, agregamos 'view=app' a queryParams
            if (queryViewApp) {
              queryParams.view = 'app';
            }
            // Si queryForce es verdadero, agregamos 'force=OK' a queryParams
            if (queryForce) {
              queryParams.force = 'OK';
            }
          }

          this.router.navigate([navigateURL], { queryParams });
        }
      }
    }  

    parseData(map: any) {
      const body_map: any = Object.fromEntries(
        map.map((e: string) => [e.split(':')[0], e.split(':')[1]])
      );
      body_map.tripettoId = '';
      body_map['cookie-bearer-token'] = this.cookies_util.get('id_token_user');
      body_map['cookie-phone'] = this.cookies_util.get('phone-full');
      body_map['cookie-process-uuid'] = this.cookies_util.get('id_process');
      return body_map;
    }

    queryParamsObj(params: any) {
      let importquerys: any[] = []; // Inicializa importquerys como un array vacío
      for (const [key, value] of params) {
        // each 'entry' is a [key, value] tuple
        importquerys.push({
          name: key,
          value: value,
        });
      }
      return importquerys;
    }
  
    @HostListener('window:resize', ['$event'])
    getResolutionScreen() {
      const screenWidth = window.innerWidth;
      if (screenWidth < 500) {
        return 11;
      } else if (screenWidth < 765) {
        return 12;
      } else if (screenWidth < 900) {
        return 12.5;
      } else if (screenWidth < 1200) {
        return 13.5;
      } else if (screenWidth < 1500) {
        return 14;
      } else {
        return 15;
      }
    }

    objStyles(bankname: any): {} {
      let color = '';
      if (bankname != this.globals_var.FLAMINGO) {
        color = '#111bff';
      } else {
        color = '#ff0050';
      }
      return {
        inputs: {
          textColor: color,
          roundness: 4,
          borderColor: color,
          borderSize: 2,
          selectionColor: color,
        },
        verticalAlignment: 'top',
        //hidePast: true,
        color: '#3a3a3a',
        /* autoFocus: false, */
        font: {
          family: 'Arial',
          size: this.getResolutionScreen(),
          sizeSmall: this.getResolutionScreen(),
        },
        buttons: {
          baseColor: color,
          mode: 'fill',
          finishColor: '#00db2f',
          textColor: 'white',
        },
        noBranding: true,
        background: {
          color: 'transparent',
        },
      };
    }

    async showLoadingAnimation(): Promise<{
      screenBlocked: boolean;
      opacity: number;
      classView: string;
    }> {
      const result: any = {};
      return new Promise((resolve) => {
        setTimeout(() => {
          /* Después de 4000 milisegundos, cambiamos los valores */
          result.opacity = 1;
          result.screenBlocked = false;
          result.classView = 'show-tripetto';
          /* Habilitamos el formulario */
          resolve(result);
        }, 500);
      });
    }

    getQueryParamsURL(param:any){
      if (isPlatformBrowser(this.platformId)){
        let return_param;
        this.route.queryParams.subscribe((params: any) => {
          return_param = params[param];
        });
        return return_param;
      }
    }
}