
import { NgModule } from '@angular/core';

@NgModule({
    providers:[CloseModal]
})

export class CloseModal { 

    _CloseModal(element: any) {
        let attempts = 0;
        const maxAttempts = 10;
        const intervalTime = 100; // 100ms
    
        const intervalId = setInterval(() => {
            attempts++;
            let name: HTMLElement | null = null;
            if (element) {
                name = document.getElementsByClassName(element)[0] as HTMLElement;
            }
    
            if (name) {
                name.click();
                clearInterval(intervalId); // Stop the interval if the element is found and clicked
            } else if (attempts >= maxAttempts) {
                clearInterval(intervalId); // Stop the interval after maxAttempts
                console.log(`Element with class "${element}" not found after ${maxAttempts} attempts.`);
            }
        }, intervalTime);
    }
}