import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import confetti from 'canvas-confetti';

@Injectable({
  providedIn: 'root'
})
export class ConfettiService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object
  ){

  }

  celebrate(element: string, model: string) {
    if(isPlatformBrowser(this.platformId)){
      const pollElement = (attempts: number) => {
        const ELEMENT_WITH_CANVAS: any = document.querySelector(element);
  
        if (ELEMENT_WITH_CANVAS) {
          this.startConfetti(ELEMENT_WITH_CANVAS, model);
        } else if (attempts > 0) {
          setTimeout(() => pollElement(attempts - 1), 50);
        } else {
          console.error('Element not found after maximum attempts');
        }
      };
  
      // Start polling with 100 attempts
      pollElement(100);
    }
  }

  startConfetti(element: any, model: string) {
    const CREATE_CANVAS: any = document.createElement('canvas');

    // Aplicar los estilos al canvas
    CREATE_CANVAS.style.width = '100%';
    CREATE_CANVAS.style.height = '100%';
    CREATE_CANVAS.style.position = 'absolute';
    CREATE_CANVAS.style.top = '0px';
    CREATE_CANVAS.style.left = '0px';
    CREATE_CANVAS.style.pointerEvents = 'none'; 

    element.appendChild(CREATE_CANVAS);

    const NEW_CONFETTI = confetti.create(CREATE_CANVAS, {
      resize: true,
      useWorker: true
    });

    const duration = 2 * 1000;
    const animationEnd = Date.now() + duration;
    let skew = 1;
    const end = Date.now() + (1 * 1000);

    const CONFETTI_SNOW = () => {
      const timeLeft = animationEnd - Date.now();
      const ticks = Math.max(200, 500 * (timeLeft / duration));
      skew = Math.max(0.8, skew - 0.001);

      NEW_CONFETTI({
        particleCount: 2,
        startVelocity: -1,
        ticks: ticks,
        origin: {
          x: Math.random(),
          // since particles fall down, skew start toward the top
          y: (Math.random() * skew) - 0.2
        },
        colors: ['#fff', '#fff'],
        shapes: ['circle'],
        gravity: this.randomInRange(0.4, 0.6),
        scalar: this.randomInRange(0.4, 1),
        drift: this.randomInRange(-0.4, 0.4)
      });

      if (timeLeft > 0) {
        requestAnimationFrame(CONFETTI_SNOW);
      }
    };

    const CONFETTI_SCHOOL_PRIDE = () => {
      var colors = ['#fff', '#fff'];

      NEW_CONFETTI({
        particleCount: 8,
        angle: 60,
        spread: 55,
        origin: { x: 0, y: 1 },
        colors: colors,
        startVelocity: 80,
      });

      NEW_CONFETTI({
        particleCount: 8,
        angle: 120,
        spread: 55,
        origin: { x: 1, y: 1 },
        colors: colors,
        startVelocity: 80,
      });

      if (Date.now() < end) {
        requestAnimationFrame(CONFETTI_SCHOOL_PRIDE);
      }
    };


    var duration_fireworks = 5 * 1000;
    var animationEnd_fireworks = Date.now() + duration_fireworks;
    var options_fireworks = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    const CONFETTI_FIREWORKS = () => {
      var colors = ['111BFF', '6B71FF', 'C4C6FF', '070B60', '111BFF']

      var interval_fireworks:any = setInterval(() => {
        var timeLeft = animationEnd_fireworks - Date.now();
      
        if (timeLeft <= 0) {
          return clearInterval(interval_fireworks);
        }
      
        var particleCount = 50 * (timeLeft / duration_fireworks);

        NEW_CONFETTI(
          { 
            ...options_fireworks, 
            particleCount, 
            colors: colors,
            origin: { 
              x: this.randomInRange(0.1, 0.3), 
              y: Math.random() - 0.2 
            } 
          }
        );

        NEW_CONFETTI(
          { 
            ...options_fireworks, 
            particleCount, 
            colors: colors,
            origin: { 
              x: this.randomInRange(0.7, 0.9), 
              y: Math.random() - 0.2 
            } 
          }
        );
      }, 250);
    };

    switch (model) {
      case 'snow':
        CONFETTI_SNOW();
        break;
      case 'school_pride':
        CONFETTI_SCHOOL_PRIDE();
        break;
      case 'fireworks':
        CONFETTI_FIREWORKS();
        break;
    }
  }

  randomInRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
  }
}
